<template>
    <div>
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="container-fluid">
                    <div id="price-circle-row" class="row">
                        <template v-for="price in defaultPrices">
                            <div v-if="dimensions[price.size]" :key="price.id" class="col-6 col-md-3 d-flex">
                                <div class="price-circle">
                                    <span class="large">{{ price.size.toUpperCase() }} {{ moneyOptionalDecimal(price.amount) }}</span>
                                    <span>{{ dimensions[price.size].length }}px / {{ dimensions[price.size].dpi }}dpi</span>
                                    <span class="small mt-4">+GST</span>
                                </div>
                            </div>
                        </template>
                        <div class="col-6 col-md-3 d-flex">
                            <nuxt-link :to="{ name: 'pre-purchase' }" class="price-circle link">
                                Discounts <br>
                                &amp; Corporate <br>
                                Accounts <br>
                                <b-icon-arrow-right class="mt-3"/>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AusIconsRow',
    data () {
        return {
            dimensions: {
                m: {
                    length: '1,600',
                    dpi: 72
                },
                l: {
                    length: '2,600',
                    dpi: 300
                },
                xl: {
                    length: '5,000',
                    dpi: 300
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            licences: 'data/licences'
        }),
        defaultPrices () {
            const defaultLicence = this.licences.image.find(licence => licence.default === 1)
            const defaultPriceTier = defaultLicence.tiers.find(tier => tier.default === 1)

            return defaultPriceTier.prices.filter(price => price.default === 1 && price.amount !== null)
        }
    }
}
</script>
