<template>
    <div>
        <div id="home-hero" ref="backgroundArea">
            <div v-if="backgroundImage && backgroundImage.contributorName" class="shot-by-pill">
                By {{ backgroundImage.contributorName }}
                <template v-if="backgroundImage.contributorRegion">, based in {{ backgroundImage.contributorRegion }}</template>
            </div>
            <div class="welcome-search-container desktop d-none d-lg-block">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 v-if="locale === 'nz'">Exclusive, royalty-free, local New Zealand stock images capturing an authentic, diverse and multicultural Aotearoa.</h1>
                            <h1 v-else-if="locale === 'aus'">Exclusive, royalty-free, local Australian stock images capturing our authentic, diverse and multicultural local life.</h1>
                            <p v-if="locale === 'nz'">
                                Truestock is your source for <strong>local royalty-free, stock images</strong>. Our stock photos authentically capture the rich diversity and multicultural essence of Aotearoa.
                                Our curated collection of New Zealand stock photos showcases all aspects of local life, from families, business owners, landscapes, to everyone who calls Aotearoa home.
                            </p>
                            <p v-else-if="locale === 'aus'">
                                Truestock is your source for <strong>local Australian royalty-free, stock images</strong>. Our stock photos authentically capture the rich diversity and multicultural essence of Australia. Our curated
                                collection of Australian
                                stock photos showcases all aspects of Australian life, from families, business owners, landscapes to everyone who calls Australia home.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="welcome-search-container mobile d-block d-lg-none">
            <div class="container">
                <div class="row pill-row">
                    <div class="col-12 col-md-9 ml-auto mr-auto genre-pill-container">
                        <h1 v-if="locale === 'nz'">Exclusive, royalty-free, local New Zealand stock images capturing an authentic, diverse and multicultural Aotearoa.</h1>
                        <h1 v-else-if="locale === 'aus'">Exclusive, royalty-free, local Australian stock images capturing our authentic, diverse and multicultural local life.</h1>
                    </div>
                </div>
            </div>
        </div>
        <div id="curated-albums-area">
            <div class="container">
                <component :is="`${capitalizeFirstLetter(locale)}IconsRow`"/>
                <div class="row">
                    <div class="col-12">
                        <h3 class="curated-title">Search above to find what you're after or get started with a small selection we've curated in galleries below</h3>
                    </div>
                </div>
                <div class="row">
                    <template v-if="curatedAlbums.length > 0">
                        <div v-for="curatedAlbum in curatedAlbums" :key="curatedAlbum.id" class="col-12 col-md-6 curated-album-col">
                            <div class="curated-album-thumbnail">
                                <nuxt-link :to="curatedAlbum.link" target="_blank">
                                    <img :src="curatedAlbum.url" :alt="curatedAlbum.name">
                                    <div class="name">
                                        {{ curatedAlbum.name }}
                                    </div>
                                </nuxt-link>
                            </div>
                        </div>
                    </template>
                    <div v-else class="text-center pt-5 pb-5 w-100">
                        <loading :loading="true" :full-page="false"/>
                    </div>
                </div>
            </div>
        </div>
        <div id="images-from-curated-albums">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <template v-if="curatedAlbums.length > 0">
                            <div id="search-results-container" class="welcome">
                                <div class="similar-images-wrap">
                                    <stock-image-thumbnail v-for="image in curatedImages" :key="image.id" :image="image"/>
                                </div>
                                <v-button v-if="!noMoreCuratedImages" :loading="loadingCuratedImages" class="btn-default btn-hollow w-auto pt-3 pb-3 mt-4 pl-5 pr-5 d-block ml-auto mr-auto h6" @click.native="getCuratedImages">
                                    See More Images/Videos
                                </v-button>
                            </div>
                        </template>
                        <div v-else class="text-center pt-2 pb-5 w-100">
                            <loading :loading="true" :full-page="false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import StockImageThumbnail from '~/components/StockImageThumbnail'
import NzIconsRow from '~/components/home/NzIconsRow'
import AusIconsRow from '~/components/home/AusIconsRow'

export default {
    components: {
        StockImageThumbnail,
        NzIconsRow,
        AusIconsRow
    },
    async asyncData () {
        const response = {}

        await axios.get('/hero-image')
            .then(rsp => rsp.data.data)
            .then((rsp) => {
                response.backgroundImage = rsp
            })

        response.randomSeed = Math.floor(Math.random() * 1000)

        return response
    },
    data: () => ({
        title: process.env.appName,
        backgroundImage: {},
        curatedAlbums: [],
        curatedImages: [],
        noMoreCuratedImages: false,
        loadingCuratedImages: false,
        page: 1,
        randomSeed: null,
        searchTerm: ''
    }),
    computed: mapGetters({
        authenticated: 'auth/check',
        licences: 'data/licences'
    }),
    mounted () {
        this.$refs.backgroundArea.style.backgroundImage = `url('${this.backgroundImage.url}')`

        axios.get('/featured-curated-albums')
            .then(rsp => rsp.data.data)
            .then((rsp) => {
                this.curatedAlbums = rsp
            })

        axios({
            method: 'get',
            url: '/featured-curated-images',
            params: {
                page: 1,
                randomSeed: this.randomSeed
            }
        })
            .then(rsp => rsp.data)
            .then((rsp) => {
                if (rsp.data.length) {
                    this.page = 2
                    this.curatedImages = rsp.data

                    if (rsp.data.length < 40) {
                        this.noMoreCuratedImages = true
                    }
                } else {
                    this.noMoreCuratedImages = true
                }
            })
    },
    methods: {
        getCuratedImages () {
            this.loadingCuratedImages = true

            axios({
                method: 'get',
                url: '/featured-curated-images',
                params: {
                    page: this.page,
                    randomSeed: this.randomSeed
                }
            })
                .then(rsp => rsp.data)
                .then((rsp) => {
                    if (rsp.data.length) {
                        this.page += 1
                        this.curatedImages.push(...rsp.data)

                        if (rsp.data.length < 40) {
                            this.noMoreCuratedImages = true
                        }
                    } else {
                        this.noMoreCuratedImages = true
                    }
                })
                .finally(() => {
                    this.loadingCuratedImages = false
                })
        },
        doSearch () {
            const state = { name: 'search' }

            if (this.searchTerm !== '') {
                state.query = { term: this.searchTerm }
            }

            this.$router.push(state).catch(() => {
            })
        }
    }
}
</script>
