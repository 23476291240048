<template>
    <div>
        <div class="row badges">
            <div class="col-12 col-xl-10 offset-xl-1 text-center">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <nuxt-link :to="{ name: 'pre-purchase' }">
                                <div>
                                    <img :src="`${assetsUrl}icons/home-pricing-icon.svg`" alt="Discounts and Pricing">
                                </div>
                                <span>
                                    Discounts &amp; Pricing
                                    <b-icon-arrow-right/>
                                </span>
                            </nuxt-link>
                        </div>
                        <div class="col-12 col-md-4">
                            <nuxt-link :to="{ name: 'tailored' }">
                                <div>
                                    <img :src="`${assetsUrl}icons/home-custom-icon.svg`" alt="Custom Production Service">
                                </div>
                                <span>
                                    Custom Production Service
                                    <b-icon-arrow-right/>
                                </span>
                            </nuxt-link>
                        </div>
                        <div class="col-12 col-md-4">
                            <nuxt-link :to="{ name: 'apply-to-join' }">
                                <div>
                                    <img :src="`${assetsUrl}icons/home-contributors-icon.svg`" alt="For Photographers">
                                </div>
                                <span>
                                    For Photographers
                                    <b-icon-arrow-right/>
                                </span>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NzIconsRow'
}
</script>
